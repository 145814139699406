// FileUploader.js
import React, { useState } from 'react';
import axios from 'axios';
import * as XLSX from 'xlsx';
import baseUrl from "../config";
import Navbar from "./Navbar";
import {toast, ToastContainer} from "react-toastify";


const FileUploader = () => {
    const [academicYear, setAcademicYear] = useState("2023-2024");
    const [radioValue, setRadioValue] = useState("1");
    const [selectedFile, setSelectedFile] = useState(null);

    // Academic year options
    const years = [
        "2023-2024",
        "2024-2025",
        "2025-2026",
        "2026-2027",
        "2027-2028",
        "2028-2029",
        "2029-2030"
    ];

    // Handle file selection
    const handleFileChange = (event) => {
        setSelectedFile(event.target.files[0]);
    };

    // Parse Excel file to JSON
    const parseExcel = async (file) => {
        const reader = new FileReader();
        return new Promise((resolve, reject) => {
            reader.onload = (e) => {
                const data = new Uint8Array(e.target.result);
                const workbook = XLSX.read(data, { type: 'array' });
                const parsedData = [];

                workbook.SheetNames.slice(1).forEach((sheetName) => { // Skip the first sheet
                    const sheet = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName], { header: 1 });

                    // Extract the first row (headers and data for id_kelas, nama_kelas)
                    const firstRow = sheet[1];
                    const id_kelas = firstRow[2];  // Assuming 'id_kelas' is the third column
                    const nama_kelas = firstRow[3];  // Assuming 'nama_kelas' is the fourth column

                    // Validation: if id_kelas or nama_kelas is missing, skip this sheet
                    if (!id_kelas || !nama_kelas) {
                        console.log(`Sheet "${sheetName}" is missing id_kelas or nama_kelas.`);
                        return;
                    }


                    const gender = nama_kelas.split(' ').pop().toLowerCase();  // Extract gender from nama_kelas

                    // Gender validation: only allow 'pa' or 'pi'
                    if (gender !== 'pa' && gender !== 'pi') {
                        console.log(`Invalid gender "${gender}" in sheet "${sheetName}". Skipping...`);
                        return;
                    }

                    // Process rows (skip the header)
                    sheet.slice(1).forEach(row => {
                        const nama = row[0];  // Assuming 'nama' is the first column
                        const id_santri = row[1];  // Assuming 'id_santri' is the second column

                        // Validation: ensure nama and id_santri are present
                        if (!nama || !id_santri) return;

                        parsedData.push({
                            nama,
                            id_santri:id_santri.toString(),
                            kelas: nama_kelas,
                            id_kelas:id_kelas.toString(),
                            gender,
                            tahun_ajaran: academicYear,
                            semester: radioValue
                        });
                    });
                });

                resolve(parsedData);
            };
            reader.onerror = reject;
            reader.readAsArrayBuffer(file);
        });
    };


    // Parse Excel file and return the parsed data as JSON
    const parseExcelAdmin = async (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = (e) => {
                const data = new Uint8Array(e.target.result);
                const workbook = XLSX.read(data, { type: "array" });
                const worksheet = workbook.Sheets[workbook.SheetNames[0]]; // First sheet

                const rows = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

                if (rows.length < 2) {
                    reject("Invalid file format or empty sheet.");
                    return;
                }

                const result = [];
                for (let i = 1; i < rows.length; i++) {
                    const row = rows[i];
                    if (!row[0] && !rows[i + 1]?.[0]) break; // Stop when two consecutive rows are empty

                    if (!row[0]) continue; // Skip empty rows

                    const nama = row[0];
                    const nomer_hape = row[1];
                    const isAdmin = row[2];
                    const id_kelas = row[3];

                    const jsonData = {
                        nama: nama,
                        nomer_hape: nomer_hape,
                        katasandi: `madin${nomer_hape.slice(-3)}`,
                        id_kelas: id_kelas,
                        isAdmin: !!isAdmin, // Convert to boolean
                    };

                    result.push(jsonData);
                }

                resolve(result);
            };

            reader.onerror = (err) => reject(err);
            reader.readAsArrayBuffer(file);
        });
    };

    // Handle form submission (Upload button click)
    const handleSubmit = async (event) => {
        event.preventDefault();

        // Validation: check if dropdown and radio are selected
        if (!academicYear || !radioValue) {
            console.log("Dropdown or radio option not selected.");
            return;
        }

        if (!selectedFile) {
            console.log("No file selected.");
            toast.error("Error: Anda belum memilih File")
            return;
        }

        try {
            const parsedData = await parseExcel(selectedFile);
            const parsedDataAdmin = await parseExcelAdmin(selectedFile);

            console.log(parsedDataAdmin)

            if (parsedData.length > 0 && parsedDataAdmin.length>0) {
                // console.log(parsedData)
                const response = await axios.post(`${baseUrl}/peserta-bulk`, parsedData);
                const response2 = await axios.post(`${baseUrl}/bulk-user`, parsedDataAdmin);
                console.log("Server response:", response.data);
                console.log("Server response:", response2.data);
                toast.success("Data Admin & Peserta Ujian berhadil diperbarui",{autoClose:2000})
            } else {
                toast.error("Gagal mengunggah Data, Pastikan Data Admin & Peserta Sudah sesuai format")
                console.log("No valid data to send.");
            }
        } catch (error) {
            console.error("Error uploading data:", error);

            // Handle server response errors
            if (error.response && (error.response.status === 400 || error.response.status === 500)) {
                console.error("Server responded with error:", error.response.data);
            }
        }
    };

    return (
        <div>
            <Navbar/>
            <ToastContainer/>
        <div className="p-6 max-w-md mx-auto bg-white rounded-xl shadow-md space-y-4">
            {/* Dropdown for Academic Year */}
            <div>
                <label className="block text-sm font-medium text-gray-700">Pilih Tahun Ajaran:</label>
                <select
                    className="mt-1 block w-full rounded-md border p-2 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    value={academicYear}
                    onChange={(e) => setAcademicYear(e.target.value)}
                >
                    {years.map((year, index) => (
                        <option key={index} value={year}>
                            {year}
                        </option>
                    ))}
                </select>
            </div>

            {/* Radio Buttons for Option */}
            <div>
                {/*<label className="block text-sm font-medium text-gray-700">Select Option:</label>*/}
                <div className="mt-4 space-y-2">
                    <label className="flex items-center">
                        <input
                            type="radio"
                            name="option"
                            value="1"
                            checked={radioValue === "1"}
                            onChange={(e) => setRadioValue(e.target.value)}
                            className="h-4 w-4 text-indigo-600 border-gray-300"
                        />
                        <span className="ml-2 text-gray-700">Semester 1</span>
                    </label>
                    <label className="flex items-center">
                        <input
                            type="radio"
                            name="option"
                            value="2"
                            checked={radioValue === "2"}
                            onChange={(e) => setRadioValue(e.target.value)}
                            className="h-4 w-4 text-indigo-600 border-gray-300"
                        />
                        <span className="ml-2 text-gray-700">Semester 2</span>
                    </label>
                </div>
            </div>

            {/* Excel File Selector */}
            <div>
                <label className="block text-sm font-medium text-gray-700">Pilih File Excel:</label>
                <input
                    type="file"
                    accept=".xlsx, .xls"
                    className="mt-2 block w-full text-sm text-gray-900 file:mr-4 file:py-2 file:px-4 file:rounded-full file:border-0 file:text-sm file:font-semibold file:bg-indigo-50 file:text-indigo-700 hover:file:bg-indigo-100"
                    onChange={handleFileChange}
                />
            </div>

            {/* Upload Button */}
            <div>
                <button
                    type="button"
                    className="mt-4 w-full bg-indigo-600 text-white py-2 px-4 rounded-md hover:bg-indigo-700"
                    onClick={handleSubmit}
                >
                    Upload
                </button>
            </div>
        </div>
        </div>
    );
};

export default FileUploader;
