import React, { useState } from 'react';
import { useQuery } from 'react-query';
import axios from 'axios';
import baseUrl from "../config";



const RekapRapot = () => {
    const [listNilaiRapotPeserta, setListNilaiRapotPeserta] = useState([]);
    const [isModalRapotOpen, setIsModalRapotOpen] = useState(false); // Modal state
    const dataTahunAjaran = JSON.parse(localStorage.getItem('dataTahunAjaran'));
    const { semester, tahunAjaran, id_kelas } = dataTahunAjaran || {};

    const fetchRapotData = async () => {
        const response = await axios.get(`${baseUrl}/hitung-rapot-batch`, {
            params: { semester, tahunAjaran, id_kelas },
        });
        return response.data;
    };

    const { refetch, isLoading, isError, data } = useQuery('rapotData', fetchRapotData, {
        enabled: false, // Only run when refetch is called
        onSuccess: (data) => {
            setListNilaiRapotPeserta(data);
            setIsModalRapotOpen(true); // Open the modal after data is fetched
        },
    });

    const handleProsesClick = () => {
        refetch();
    };

    const closeModalRapot = () => {
        setIsModalRapotOpen(false);
    };

    // Function to map kategori_fan to a background color
    const getBgColorByKategoriFan = (kategori_fan) => {
        if (kategori_fan === 10) return 'bg-blue-200';
        if (kategori_fan === 20) return 'bg-blue-400';
        if (kategori_fan === 30) return 'bg-red-200';
        if (kategori_fan === 40) return 'bg-red-400';
        if (kategori_fan === 50) return 'bg-green-200';
        if (kategori_fan === 55) return 'bg-indigo-200';
        if (kategori_fan === 60) return 'bg-green-400';
        if (kategori_fan === 70) return 'bg-yellow-200';
        if (kategori_fan === 80) return 'bg-yellow-400';
        if (kategori_fan === 90) return 'bg-purple-200';
        return 'bg-gray-100'; // Default
    };

    return (
        <div className="p-6">
            <h1 className="text-2xl font-bold mb-4">Rekap Rapot</h1>
            <button
                onClick={handleProsesClick}
                className="bg-blue-500 text-white px-4 py-2 rounded mb-6"
            >
                Proses
            </button>

            {isLoading && <p>Loading...</p>}
            {isError && <p>Error fetching data...</p>}

            {isModalRapotOpen && (
                <div className="fixed inset-0 bg-gray-900 bg-opacity-50 flex items-center justify-center z-50">
                    <div className="bg-white rounded-lg shadow-lg w-full max-w-4xl h-5/6 overflow-auto p-4">
                        <div className="flex justify-between items-center">
                            <h2 className="text-xl font-bold">Nilai Rapot</h2>
                            <button
                                onClick={closeModalRapot}
                                className="text-white bg-red-500 px-4 py-2 rounded"
                            >
                                Close
                            </button>
                        </div>

                        <div className="mt-4">
                            <table className="min-w-full bg-white border-collapse">
                                <thead>
                                <tr>
                                    <th className="border px-4 py-2">Nama</th>
                                    {/* Sort nilai_rapot by no_urut before rendering */}
                                    {listNilaiRapotPeserta[0]?.nilai_rapot
                                        .sort((a, b) => a.no_urut - b.no_urut)
                                        .map((nilai) => (
                                            <th
                                                key={nilai.no_urut}
                                                className="border px-4 py-2 rotate-90"
                                                style={{ writingMode: 'vertical-rl', transform: 'rotate(180deg)' }}
                                            >
                                                {nilai.nama_nilai}
                                            </th>
                                        ))}
                                </tr>
                                </thead>
                                <tbody>
                                {listNilaiRapotPeserta.map((peserta) => (
                                    <tr key={peserta.id_peserta}>
                                        <td className="border px-4 py-2">{peserta.nama}</td>
                                        {/* Sort nilai_rapot by no_urut before rendering */}
                                        {peserta.nilai_rapot
                                            .sort((a, b) => a.no_urut - b.no_urut)
                                            .map((nilai) => (
                                                <td
                                                    key={nilai.no_urut}
                                                    className={`border px-4 py-2 text-center ${getBgColorByKategoriFan(
                                                        nilai.kategori_fan
                                                    )}`}
                                                >
                                                    {nilai.isi_nilai}
                                                </td>
                                            ))}
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default RekapRapot;
