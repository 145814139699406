import React, { useState, useEffect } from 'react';
import axios from 'axios';
import baseUrl from "../config";
import Navbar from "./Navbar";
import { ToastContainer } from "react-toastify";
import { Trash2Icon } from "lucide-react";

const DataPeserta = () => {
    const [gender, setGender] = useState('Pa');
    const [listKelas, setListKelas] = useState([]);
    const [selectedKelas, setSelectedKelas] = useState('');
    const [listPesertaKelas, setListPesertaKelas] = useState([]);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [editPeserta, setEditPeserta] = useState(null);

    const dataTahunAjaran = JSON.parse(localStorage.getItem('dataTahunAjaran'));

    // Fetch classes based on semester and tahunAjaran
    useEffect(() => {
        const fetchClasses = async () => {
            try {
                const response = await axios.get(`${baseUrl}/list-kelas`, {
                    params: {
                        semester: dataTahunAjaran.semester,
                        tahunAjaran: dataTahunAjaran.tahunAjaran,
                    },
                });
                setListKelas(response.data.listKelas);
            } catch (error) {
                console.error('Error fetching classes:', error);
            }
        };

        if (dataTahunAjaran) {
            fetchClasses(); // Fetch only once on mount
        }
    }, []); // Empty dependency array to only run this once on mount

    const filteredKelas = listKelas.filter((kelas) =>
        kelas.nama_kelas.toLowerCase().includes(` ${gender.toLowerCase()}`)
    );

    // Handle fetching students based on the selected class
    const handleProses = async () => {
        if (!selectedKelas) return; // Avoid fetching if no class is selected
        try {
            const response = await axios.get(`${baseUrl}/peserta-perkelas`, {
                params: {
                    semester: dataTahunAjaran.semester,
                    tahunAjaran: dataTahunAjaran.tahunAjaran,
                    id_kelas: selectedKelas,
                },
            });
            setListPesertaKelas(response.data);
        } catch (error) {
            console.error('Error fetching students:', error);
        }
    };

    const handleDelete = async (pesertaId) => {
        try {
            await axios.delete(`${baseUrl}/peserta/${pesertaId}`);
            setIsDeleteModalOpen(false);
            handleProses(); // Refetch list after deletion
        } catch (error) {
            console.error('Error deleting peserta:', error);
        }
    };

    const handleEditSave = async () => {
        try {
            await axios.patch(`${baseUrl}/peserta/${editPeserta.id}`, editPeserta);
            setIsEditModalOpen(false);
            handleProses(); // Refetch list after editing
        } catch (error) {
            console.error('Error editing peserta:', error);
        }
    };

    return (
        <div>
            <Navbar />
            <ToastContainer />
            <div className="p-4">
                <div className="mb-2 flex items-center justify-between border border-blue-800 px-4 py-2 rounded-lg">
                    <label className="mr-1">
                        <input
                            type="radio"
                            value="Pa"
                            checked={gender === 'Pa'}
                            onChange={() => setGender('Pa')}
                            className="mr-2"
                        />
                        Pa
                    </label>
                    <label>
                        <input
                            type="radio"
                            value="Pi"
                            checked={gender === 'Pi'}
                            onChange={() => setGender('Pi')}
                            className="mr-2 text"
                        />
                        Pi
                    </label>
                    <select
                        value={selectedKelas}
                        onChange={(e) => setSelectedKelas(e.target.value)}
                        className="p-2 border border-gray-300 rounded-xl bg-white"
                    >
                        <option value="">Pilih Kelas</option>
                        {filteredKelas?.map((kelas) => (
                            <option key={kelas.id_kelas} value={kelas.id_kelas}>
                                {kelas.nama_kelas}
                            </option>
                        ))}
                    </select>

                    <button
                        onClick={handleProses}
                        className="py-2 px-3 bg-indigo-700 text-white rounded-md transition-all transform active:scale-90 active:shadow-inner"
                    >
                        Proses
                    </button>
                </div>

                {/* Peserta Table */}
                <table className=" mt-4 ml-5 pb-2 border-collapse border">
                    <thead>
                    <tr>
                        <th className="border p-1">#</th>
                        <th className="border p-1">Nama Peserta</th>
                        <th className="border p-1">Aksi</th>
                    </tr>
                    </thead>
                    <tbody>
                    {listPesertaKelas.map((peserta, index) => (
                        <tr key={peserta.id} className={`${index % 2 === 0 ? 'bg-white' : 'bg-gray-100'}`}>
                            <td className="border p-1 text-center text-xs">{index + 1}</td>
                            <td className="border p-1">{peserta.nama}</td>
                            <td className="ml-1 px-1 mt-2 flex gap-2">
                                <button
                                    className="bg-yellow-500 text-white px-2 py-1 rounded mr-2"
                                    onClick={() => {
                                        setEditPeserta(peserta);
                                        setIsEditModalOpen(true);
                                    }}
                                >
                                    Edit
                                </button>
                                <Trash2Icon
                                    className="bg-red-500 text-white w-8 h-8 px-2 py-1 rounded"
                                    onClick={() => setIsDeleteModalOpen(true)}
                                />
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </table>

                {/* Delete Modal */}
                {isDeleteModalOpen && (
                    <div className="fixed inset-0 flex justify-center items-center bg-black bg-opacity-50">
                        <div className="bg-white p-4 rounded">
                            <p>Are you sure you want to delete this peserta?</p>
                            <div className="flex justify-end mt-4">
                                <button
                                    className="bg-gray-500 text-white px-4 py-2 rounded mr-2"
                                    onClick={() => setIsDeleteModalOpen(false)}
                                >
                                    Batal
                                </button>
                                <button
                                    className="bg-red-500 text-white px-4 py-2 rounded"
                                    onClick={() => handleDelete(editPeserta.id)}
                                >
                                    Ya, Hapus
                                </button>
                            </div>
                        </div>
                    </div>
                )}

                {/* Edit Modal */}
                {isEditModalOpen && (
                    <div className="fixed inset-0 flex justify-center items-center bg-black bg-opacity-50">
                        <div className="bg-white p-4 rounded mx-4">
                            <p>Edit Peserta</p>
                            <input
                                className="border p-2 mb-2"
                                value={editPeserta.nama}
                                onChange={(e) =>
                                    setEditPeserta({ ...editPeserta, nama: e.target.value })
                                }
                            />

                            <div className="mb-2">
                                <label className="mr-4">Gender:</label>
                                <label className="mr-2">
                                    <input
                                        type="radio"
                                        name="gender-edit"
                                        value="Pa"
                                        checked={editPeserta.gender === 'pa'}
                                        onChange={() => setEditPeserta({ ...editPeserta, gender: 'pa' })}
                                    />
                                    Pa
                                </label>
                                <label>
                                    <input
                                        type="radio"
                                        name="gender-edit"
                                        value="Pi"
                                        checked={editPeserta.gender === 'pi'}
                                        onChange={() => setEditPeserta({ ...editPeserta, gender: 'pi' })}
                                    />
                                    Pi
                                </label>
                            </div>
                            <div className="flex justify-end mt-4">
                                <button
                                    className="bg-gray-500 text-white px-4 py-2 rounded mr-2"
                                    onClick={() => setIsEditModalOpen(false)}
                                >
                                    Cancel
                                </button>
                                <button
                                    className="bg-green-500 text-white px-4 py-2 rounded"
                                    onClick={handleEditSave}
                                >
                                    Save
                                </button>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default DataPeserta;
