import {UserPlusIcon} from "lucide-react";
import {BuildingLibraryIcon, DocumentChartBarIcon} from "@heroicons/react/16/solid";
import {TableCellsIcon} from "@heroicons/react/24/solid";
import Navbar from "./Navbar";
import {ToastContainer} from "react-toastify";
import {useNavigate} from "react-router-dom";

export const PanelAdmin = () => {

    const navigate = useNavigate();
    return (
        <div>
            <Navbar/>
            <ToastContainer/>

            <div className={`flex justify-center space-x-7 mt-6`}>
                <div
                    onClick={()=>{navigate('tambah-peserta')}}
                    className={`flex flex-col items-center justify-center bg-orange-500 text-white py-5 w-28 rounded-md overflow-hidden shadow-lg shadow-orange-800`}>
                    <TableCellsIcon className="h-10 w-10 text-white" aria-hidden="true" />
                    <p className="mt-4 font-bold text-xs text-center">
                        Tambah Peserta
                    </p>
                </div>
                <div
                    onClick={()=>{navigate('daftar-pengguna')}}
                    className={`flex flex-col items-center justify-center bg-orange-500 text-white py-5 w-28 rounded-md overflow-hidden shadow-lg shadow-orange-800`}>
                    <BuildingLibraryIcon className="h-10 w-10 text-white" aria-hidden="true" />
                    <p className="mt-4 font-bold text-xs text-center">
                        Daftar Admin
                    </p>
                </div>

                {/*<div*/}
                {/*    onClick={()=>{navigate('unggah-data')}}*/}
                {/*    className={`flex flex-col items-center justify-center bg-orange-500 text-white py-5 w-28 rounded-md overflow-hidden shadow-lg shadow-orange-800`}>*/}
                {/*    <DocumentChartBarIcon className="h-10 w-10 text-white" aria-hidden="true" />*/}
                {/*    <p className="mt-4 font-bold text-xs text-center">*/}
                {/*        Unggah data RFID*/}
                {/*    </p>*/}
                {/*</div>*/}

            </div>











        </div>
    )
}