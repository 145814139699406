// UserList.js

import React, { useState, useEffect } from "react";
import axios from "axios";
import { Switch } from "@headlessui/react";
import baseUrl from "../config";
import Navbar from "./Navbar";
import {toast, ToastContainer} from "react-toastify";
import {Trash2Icon, UserPlusIcon} from "lucide-react";
import {ArrowPathIcon} from "@heroicons/react/16/solid"; // For the toggle switch in edit modal

export const UserList = () => {
    const [listUsers, setListUsers] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedUser, setSelectedUser] = useState(null);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [isAddModalOpen, setIsAddModalOpen] = useState(false);
    const [formData, setFormData] = useState({
        nama: "",
        nomer_hape: "",
        id_kelas: "",
        isAdmin: false,
    });

    const handleAddUser = () => {
        // Clear the form data for a new user
        setFormData({
            nama: "",
            nomer_hape: "",
            id_kelas: "",
            katasandi: "",
            isAdmin: false,
        });
        setIsAddModalOpen(true);
    };

    // Handle adding a new user (POST request)
    const handleAddSave = async () => {
        try {
            await axios.post(`${baseUrl}/user`, formData);
            setIsAddModalOpen(false); // Close the modal on success
            fetchUsers(); // Refresh the user list
        } catch (error) {
            console.error("Failed to add user:", error);
        }
    };

    // Fetch users when the component mounts or when refreshed
    const fetchUsers = async () => {
        try {
            const response = await axios.get(`${baseUrl}/user`);
            setListUsers(response.data);
            toast.success("Data berhasil diperbarui",{autoClose:500})
        } catch (error) {
            console.error("Failed to fetch users:", error);
        }
    };

    // Call fetchUsers initially
    useEffect(() => {
        fetchUsers();
    }, []);

    // Handle delete user confirmation
    const handleDelete = (user) => {
        setSelectedUser(user);
        setIsDeleteModalOpen(true);
    };

    // Confirm and delete the user
    const confirmDelete = async () => {
        try {

            await axios.delete(`${baseUrl}/user/${selectedUser}`);
            setIsDeleteModalOpen(false); // Close the confirmation modal
            fetchUsers(); // Refresh the user list after deletion
        } catch (error) {
            console.error("Failed to delete user:", error);
        }
    };

    // Open the edit modal and populate with selected user data
    const handleEdit = (user) => {
        setSelectedUser(user);
        setFormData({
            nama: user.nama,
            nomer_hape: user.nomer_hape,
            id_kelas: user.id_kelas,
            isAdmin: user.isAdmin,
        });
        setIsModalOpen(true);
    };

    // Handle saving user updates (PATCH request)
    const handleSave = async () => {
        try {
            await axios.patch(`${baseUrl}/user/${selectedUser.id}`, formData);
            setIsModalOpen(false); // Close the modal on success
            fetchUsers(); // Refresh the user list
        } catch (error) {
            console.error("Failed to update user:", error);
        }
    };

    // Update form data as user inputs
    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    return (
        <div> <Navbar/>
            <ToastContainer/>
        <div className="px-4 my-4">
            <div className="flex space-x-4 mb-4">
                <ArrowPathIcon
                    className="h-9 w-9 bg-orange-500 text-white rounded-md p-1"
                    onClick={fetchUsers}
                />


                <UserPlusIcon
                    className="h-9 w-9 bg-teal-500 text-white  rounded-md p-1"
                    onClick={handleAddUser}
                />

            </div>

            {/* User Table */}
            <table className="bg-white border">
                <thead>
                <tr>
                    <th className="px-1 py-1 border-b text-sm ">No</th>
                    <th className="px-1 py-1 border-b text-sm">Nama</th>

                    <th className="px-1 py-1 border-b text-sm">Kelas</th>
                    <th className="px-1 py-1 border-b text-sm">Status</th>
                    <th className="px-1 py-1 border-b text-sm">Aksi</th>
                </tr>
                </thead>
                <tbody>
                {listUsers.map((user, index) => (
                    <tr key={user.id} className={`${index%2===0? 'bg-white':'bg-yellow-200' }`}>
                        <td className="px-1 py-1.5  text-xs ">{index + 1}</td>
                        <td className=" px-1 py-1.5  text-xs whitespace-nowrap flex flex-col">
                            <span className={`font-bold`}>{user.nama}</span>
                            <span className={``}>{user.nomer_hape}</span>
                        </td>

                        <td className="px-1 py-1.5  text-xs">{user.id_kelas}</td>
                        <td className="px-1 py-1.5  text-xs ">
                            {user.isAdmin ? "Admin" : "Mustahiq"}
                        </td>
                        <td className="ml-1 px-1 mt-2 flex gap-2">
                            <button
                                className="bg-indigo-500 text-white px-2 py-0.5 rounded-md text-sm "
                                onClick={() => handleEdit(user)}
                            >
                                Edit
                            </button>
                            <Trash2Icon
                                className="bg-red-500 text-white p-1 rounded-md text-sm"
                                onClick={() => handleDelete(user.id)}
                            />


                        </td>
                    </tr>
                ))}
                </tbody>
            </table>

            {/* Edit Modal */}
            {isModalOpen && (
                <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
                    <div className="bg-white p-6 rounded-md">
                        <h2 className="text-xl mb-4">Edit Mustahiq</h2>
                        <div className="mb-4">
                            <label>Nama</label>
                            <input
                                type="text"
                                name="nama"
                                value={formData.nama}
                                onChange={handleChange}
                                className="border rounded w-full px-2 py-1 mt-1"
                            />
                        </div>
                        <div className="mb-4">
                            <label>Nomer Hape</label>
                            <input
                                type="text"
                                name="nomer_hape"
                                value={formData.nomer_hape}
                                onChange={handleChange}
                                className="border rounded w-full px-2 py-1 mt-1"
                            />
                        </div>
                        <div className="mb-4">
                            <label>Kelas</label>
                            <input
                                type="text"
                                name="id_kelas"
                                value={formData.id_kelas}
                                onChange={handleChange}
                                className="border rounded w-full px-2 py-1 mt-1"
                            />
                        </div>
                        <div className="mb-4 flex space-x-4">
                            <label>Admin</label>
                            <Switch
                                checked={formData.isAdmin}
                                onChange={(value) =>
                                    setFormData({ ...formData, isAdmin: value })
                                }
                                className={`${
                                    formData.isAdmin ? "bg-green-500" : "bg-gray-200"
                                } relative inline-flex items-center h-6 rounded-full w-11`}
                            >
                <span
                    className={`${
                        formData.isAdmin ? "translate-x-6" : "translate-x-1"
                    } inline-block w-4 h-4 transform bg-white rounded-full`}
                />
                            </Switch>
                        </div>
                        <div className="flex justify-end">
                            <button
                                className="bg-gray-500 text-white px-4 py-2 rounded-md mr-2"
                                onClick={() => setIsModalOpen(false)}
                            >
                                Cancel
                            </button>
                            <button
                                className="bg-blue-500 text-white px-4 py-2 rounded-md"
                                onClick={handleSave}
                            >
                                Save
                            </button>
                        </div>
                    </div>
                </div>
            )}

            {/* Delete Confirmation Modal */}
            {isDeleteModalOpen && (
                <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
                    <div className="bg-white p-6 rounded-md mx-4">
                        <h2 className="text-xl mb-4">Are you sure you want to delete this user?</h2>
                        <div className="flex justify-end space-x-4">
                            <button
                                className="bg-gray-500 text-white px-4 py-2 rounded-md"
                                onClick={() => setIsDeleteModalOpen(false)}
                            >
                                No
                            </button>
                            <button
                                className="bg-red-500 text-white px-4 py-2 rounded-md"
                                onClick={confirmDelete}
                            >
                                Yes
                            </button>
                        </div>
                    </div>
                </div>
            )}

            {/* Add User Modal */}
            {isAddModalOpen && (
                <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
                    <div className="bg-white p-6 rounded-md">
                        <h2 className="text-xl mb-4">Tambah Pengguna/Mustahiq baru</h2>
                        <div className="mb-4">
                            <label>Nama</label>
                            <input
                                type="text"
                                name="nama"
                                value={formData.nama}
                                onChange={handleChange}
                                className="border rounded w-full px-2 py-1 mt-1"
                            />
                        </div>
                        <div className="mb-4">
                            <label>Nomer Hape</label>
                            <input
                                type="text"
                                name="nomer_hape"
                                value={formData.nomer_hape}
                                onChange={handleChange}
                                className="border rounded w-full px-2 py-1 mt-1"
                            />
                        </div>
                        <div className="mb-4">
                            <label>Kode Kelas</label>
                            <input
                                type="text"
                                name="id_kelas"
                                value={formData.id_kelas}
                                onChange={handleChange}
                                className="border rounded w-full px-2 py-1 mt-1"
                            />
                        </div>
                        <div className="mb-4">
                            <label>Katasandi</label>
                            <input
                                type="text"
                                name="katasandi"
                                value={formData.katasandi}
                                onChange={handleChange}
                                className="border rounded w-full px-2 py-1 mt-1"
                            />
                        </div>
                        <div className="mb-4 flex space-x-4">
                            <label >Admin</label>
                            <Switch
                                checked={formData.isAdmin}
                                onChange={(value) =>
                                    setFormData({ ...formData, isAdmin: value })
                                }
                                className={`${
                                    formData.isAdmin ? "bg-green-500" : "bg-gray-200"
                                } relative inline-flex items-center h-6 rounded-full w-11`}
                            >
                <span
                    className={`${
                        formData.isAdmin ? "translate-x-6" : "translate-x-1"
                    } inline-block w-4 h-4 transform bg-white rounded-full`}
                />
                            </Switch>
                        </div>
                        <div className="flex justify-end">
                            <button
                                className="bg-gray-500 text-white px-4 py-2 rounded-md mr-2"
                                onClick={() => setIsAddModalOpen(false)}
                            >
                                Cancel
                            </button>
                            <button
                                className="bg-blue-500 text-white px-4 py-2 rounded-md"
                                onClick={handleAddSave}
                            >
                                Save
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div></div>
    );
};


