import logo from './logo.svg';
import './App.css';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import Beranda from "./pages/Beranda";
import AuthRedirect from "./pages/auth/AuthRedirect";
import {Auth} from "./pages/auth/Auth";

import RekapNilai from "./pages/RekapNilai";
import RekapRapot from "./pages/RekapRapot";
import {PanelAdmin} from "./pages/PanelAdmin";
import FileUploader from "./pages/FileUploader";
import {UserList} from "./pages/UserList";
import {UserProfilePage} from "./pages/UserProfilePage";
import DataPeserta from "./pages/DataPeserta";

function App() {
  return (
      <div>
        <BrowserRouter>
          <Routes>
              <Route path="/" element={< AuthRedirect/>} />
              <Route path="/beranda" element={< Beranda/>} />
              <Route path="/data-peserta" element={< DataPeserta/>} />
              <Route path="/auth" element={< Auth/>} />
              <Route path="/rekap-nilai" element={< RekapNilai/>} />
              <Route path="/rapot" element={< RekapRapot/>} />
              <Route path="/panel-admin" element={< PanelAdmin/>} />
              <Route path="/profil-pengguna" element={< UserProfilePage/>} />
              <Route path="/panel-admin/tambah-peserta" element={< FileUploader/>} />
              <Route path="/panel-admin/daftar-pengguna" element={< UserList/>} />

          </Routes>
        </BrowserRouter>
      </div>
  );
}

export default App;
